.homeHeader {
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  border-width: 3px;
  border-color: #a61111;
  border-style: outset;
  margin-top: 2px;
  color: #fefefe;
}

.main-div {
  background-color: black;
}

.homeHeader,
.header-heading {
  background-color: #ffffe0;
  padding: 0px 10px;
  border-radius: 10px;
  text-align: center;
}

.homeHeader .main-row,
.section .main-row {
  padding: 10px;
}

.homeHeader a {
  text-decoration: none;
  color: #f00a3b;
}

.homeHeader .link {
  border: 1px solid #000;
  padding: 8px;
}

.header-heading h1,
.bottomResult h1 {
  color: #f00a3b;
  font-weight: bold;
  text-decoration: none;
}

.header-heading {
  border-radius: 25px !important;
  border: dotted red;
}

.header-heading h1 {
  color: red !important;
  font-size: 21px !important;
  padding-bottom: 7px !important;
}

.header-heading h6 {
  color: black !important;
  font-size: 15px !important;
  font-weight: 700;
  padding-top: 3px;
}

.bottomResult h1 {
  text-shadow: 1px 1px #00f;
}

.daywisereport h3 {
  background-color: brown;
  color: #fff;
  padding: 1rem 0px;
}

.currentBanner {
  background-color: black;
  padding: 15px 0px;
}

.currentBanner h6 {
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}

.currentBanner p {
  color: #1ff5ed;
  font-weight: bold;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
  margin-top: 10px;
}

.currentBanner button {
  background-color: white;
  color: red;
  font-weight: bold;
  border-radius: 30px;
  padding: 10px;
  font-size: 30px;
}

.result-button {
  background-color: red !important;
  color: white !important;
  font-size: medium !important;
  border: none;
  margin-top: 7px;
}

.bottomResult {
  background-color: #ffffe0;
  padding: 1rem 0px;
}

.bottomResult h3 {
  color: green;
  text-shadow: 1px 1px #00f;
  font-size: 50px;
}

.bottomResult hr {
  opacity: 1 !important;
}

.game_column {
  background-color: #030;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
  color: #fff;
  padding: 1rem 0rem;
}

.game_column h6 {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}

.game_column span {
  /* background-color: #fff; */
  /* padding: 8px 1rem; */
  /* color: red; */
  /* border-radius: 12px; */
  margin: 1rem;
}

.textColor {
  color: yellow;
  font-weight: bold;
}

/* .cardDetails {
  background-color: #FFFFE0 !important;
  color: #000;
  font-weight: bold;
  font-style: bold !important;
  font-size: large;
  text-decoration: none;
  border-width: 3px !important;
  border-color: #A61111 !important;
  border-style: outset !important;
  margin: 3px;
  padding: 10px;
  border-radius: 10px !important;
  text-align: center;
} */

/* 
.cardDetails button {
  color: #A61111;
  background-color: transparent;
  border: none;
  font-weight: bold;
} */

/* ============antd table styling========== */
/* .ant-table-content thead {
  text-align: center !important;
} */

/* .ant-table-content thead tr,
.ant-table-content thead td {
  background-color: #fbc503;
  border: 1px solid #e7aa26;
  border-radius: 0px;
}

/* background-color: #fbc503;
border: 1px solid #e7aa26;
border-radius: 0px; */

.ant-table-thead > tr > td,
.ant-table-thead th {
  border-radius: 0px !important;
  border-color: #000;
  background-color: #ffff79 !important;
  color: red !important;
  /* font-size: 0.6rem; */
  font-weight: bold;
  text-wrap: nowrap;
}

.ant-table-content td,
.ant-table-content tr,
.ant-table-content th {
  padding: 5px 0px !important;
  text-align: center !important;
  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
  text-wrap: nowrap;
}

.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child
  .ant-table-content {
  border-start-start-radius: 0px !important;
}

.ant-table-content tr {
  text-align: center !important;
  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

/* .ant-table-cell.ant-table-cell th:first-child{
  background-color: green !important;
} */

/* .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  background-color: #ffff79 !important;
  color: #A61111 !important;
  padding: 0rem 1rem !important;
} */

.ant-table-wrapper .ant-table-container table > tbody > tr > *:first-child {
  background-color: #ffff79 !important;
  /* #ffddff */
  color: red !important;
}

.ant-table-content td,
.ant-table-content th {
  padding: 10px 5px !important;
  text-align: center !important;

  border-right: 1px solid black !important;
  vertical-align: middle !important;
  border-bottom: 1px solid #000 !important;
}

.ant-table-content tr:nth-child(even) {
  background-color: #f9f9f9 !important;
}

.ant-table-wrapper table {
  border-radius: 0px !important;
  /* margin-top: -6px; */
}

table tbody tr:hover {
  background-color: #f0f0f0;
  color: red;
}

/* ========disclaimer======= */
.disclaimer p,
.disclaimer span {
  background-color: #fff;
}

/* 
.disclaimer .header {
  border-style: double;
  border-color: blue;
  background-color: purple;
  color: #FDEEF4;
  margin-bottom: 0.5rem;
} */

/* .header_2 {
  background-color: lightyellow;
  font-style: bold;
  border-color: red;
  border-style: outset;
  padding: 10px;
  border-radius: 10px;
} */
/* 
.section .link {
  padding: 8px 0px
} */

/* .section .link a {
  text-decoration: none;
  color: #fff;
} */

/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

#go-button {
  background-color: rgb(24, 109, 237);
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: navy;
}

/* refresh button */
.refreshButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: #cd0404 4px solid;
  background: #fff;
  color: Red;
  height: auto;
  padding: 8px;
  font-style: italic;
  border-radius: 10px;
  font-weight: bold;
  color: #000;
  z-index: 1;
}

/* footer button styling */
.footerButton {
  background: #fff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.footerButton a {
  text-decoration: none;
  padding-top: 2rem;
  color: red;
}

/* .footerButton .last-button {
  width: 32%;
} */

/*banner*/
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: black;
  padding: 15px;
  border: double 6px #fff;
  border-radius: 30px;
}

.game {
  text-align: center;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #837e7e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #837e7e;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #c1c6c2;
}

.about,
.faq,
.privacy,
.disclaimer,
.contact {
  background-color: white;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  color: black;
  padding: 20px;
}

.warning {
  background-color: #ffff58;
  font-family: "Times New Roman", Times, serif;
  color: black;
}
.warning p {
  color: red;
}
.marqu {
  color: red;
  font-weight: bold;
}
.warning h2 {
  font-weight: bold;
}
.about h3,
.faq h3,
.privacy h3,
.disclaimer h3,
.contact h3 {
  font-size: 18px;
  font-weight: bold;
}

.about p,
.faq p,
.privacy p,
.disclaimer p,
.contact p {
  font-size: 20px;
}

.button-container {
  display: flex;
}

.rounded-button {
  text-align: center;
  font-weight: 500;
  background-color: #f7ffb8;
  color: red;
  width: 33%;
  border: 5px solid #000;
  border-radius: 15px;
  padding: 8px;
  cursor: pointer;
  text-decoration: unset;
}

.sattaReal h5 {
  color: yellow;
}

.monthYrTbl {
  text-transform: uppercase;
}

/* ======= react toas ===== */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}

/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #fef9e7;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}
